<script setup>
import {computed, onMounted, ref} from 'vue';
import {usePage} from "@inertiajs/inertia-vue3";
import {EyeIcon, EyeSlashIcon}  from '@heroicons/vue/24/outline'

defineProps({
	modelValue: String,
	disabled: Boolean,
});

defineEmits(['update:modelValue']);

const input = ref(null);
const visible = ref(false)

onMounted(() => {
	if (input.value.hasAttribute('autofocus')) {
		input.value.focus();
	}
});

defineExpose({focus: () => input.value.focus()});
const page = usePage();
const containerDiv = ref(null)
const ringColor = computed(() => 'ring-[' + page.props.value.primiaryColor + ']');

const doSomething = (e) => {
  if(containerDiv.value != null) {
    containerDiv.value.style.borderColor = page.props.value.primaryColor;
    containerDiv.value.style.borderWidth = '1px';
    containerDiv.value.style.borderOpacity = 50;
  }
};
const doSomethingElse = (e) => {
  if(containerDiv.value != null) {
    containerDiv.value.style.borderColor = '#d1d5db'; //50 percent
    containerDiv.value.style.borderWidth = '1px';
    containerDiv.value.style.borderOpacity = 30;
  }
};

</script>

<template>
	<div ref="containerDiv" :class="[disabled ? 'bg-gray-200 text-gray-500' : 'bg-primaryWhite text-black']"
			 class="flex flex-row-reverse border border-gray-300 focus:outline-none focus:ring-0 focus:ring-offset-0 rounded-full shadow-sm">
		<div class="flex flex-row justify-center items-center px-2 cursor-pointer" @click="visible = !visible" >
			<EyeIcon v-if="visible" class="h-6 w-6"></EyeIcon>
			<EyeSlashIcon v-else class="h-6 w-6"></EyeSlashIcon>
		</div>
		<input
				@focusin="doSomething" @focusout="doSomethingElse"
				:disabled="disabled"
				ref="input"
				class="w-full bg-transparant border-none focus:outline-none focus:ring-0 focus:ring-offset-0 rounded-l-full"
				:type="!visible ? 'password' : 'text'"
				:value="modelValue"
				@input="$emit('update:modelValue', $event.target.value)"
		>

	</div>
</template>
